// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-company-tsx": () => import("./../../../src/pages/company.tsx" /* webpackChunkName: "component---src-pages-company-tsx" */),
  "component---src-pages-contact-confirm-tsx": () => import("./../../../src/pages/contact/confirm.tsx" /* webpackChunkName: "component---src-pages-contact-confirm-tsx" */),
  "component---src-pages-contact-fin-tsx": () => import("./../../../src/pages/contact/fin.tsx" /* webpackChunkName: "component---src-pages-contact-fin-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mission-tsx": () => import("./../../../src/pages/mission.tsx" /* webpackChunkName: "component---src-pages-mission-tsx" */),
  "component---src-pages-news-index-tsx": () => import("./../../../src/pages/news/index.tsx" /* webpackChunkName: "component---src-pages-news-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/Category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-news-tsx": () => import("./../../../src/templates/News.tsx" /* webpackChunkName: "component---src-templates-news-tsx" */)
}

